/* ok */
.carrousel {
  max-width: 1240px;
  width: 95%;
  height: 415px;
  margin-inline: auto;
}

/* ok */
.carrousel .sliderStyles {
  height: 100%;
  width: 100%;
  margin-inline: auto;
}

.slideStyles {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  height: 100%;
  align-items: center;
  background-position: center;
  background-size: cover;
  justify-content: space-between;
  border-radius: 25px;
  margin-inline: auto;
}

.fleches {
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: space-between;
  top: 42%;
  padding-top: auto;
  padding-inline: 23px;
}

.numerotation {
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 24px;
  margin-inline: auto;
}

.numerotation p {
  color: #ffffff;
}

img {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .fichelogement {
    width: 100%;
  }

  .carrousel {
    width: 95%;
    height: 255px;
  }

  .slideStyles {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;

    align-items: center;
    background-position: center;
    background-size: cover;
    justify-content: space-between;
    border-radius: 25px;
  }

  .fleches {
    height: 20px;
    top: 48%;
  }

  .numerotation {
    display: none;
  }
}
