.descriptifComplet {
  width: 95%;
  margin-inline: auto;
}

.descriptif_bandeau {
  display: flex;
  justify-content: space-between;
  max-width: 1240px;
  width: 100%;
  margin-inline: auto;
  margin-top: 30px;
}

h1 {
  font-size: 36px;
  font-weight: 500;
  color: #ff6060;
  margin-bottom: 8px;
}

.descriptifComplet p {
  color: #ff6060;
  font-size: 18px;
}

.descriptif_corps {
  display: flex;
  max-width: 1240px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 150px;
  margin-inline: auto;
  margin-top: 24px;
}

.descriptif_corps div {
  max-width: 582px;
  width: 100%;
}

.tags {
  margin-top: 39px;
  display: flex;
  gap: 25px;
}

.tags h4 {
  display: flex;
  background-color: #ff6060;
  color: #ffffff;
  min-width: 115px;
  align-items: center;
  justify-content: center;
  height: 25px;
  font-size: 14px;
  border-radius: 50px;
  padding-inline: 15px;
}

.host {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  gap: 10px;
  margin-bottom: 27px;
  max-width: 130px;
}

.host img {
  height: 64px;
  width: 64px;
  border-radius: 50%;
}

.host h3 {
  display: flex;
  max-width: 100px;
  text-align: right;
}

.descriptif_bandeau_right {
  display: flex;
  flex-direction: column;
  justify-content: right;
}

.stars {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .descriptifComplet {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 60px;
  }

  .descriptif_bandeau {
    flex-direction: column;
    margin-top: 0;
  }

  h1 {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .descriptifComplet p {
    font-size: 14px;
    line-height: 20px;
  }

  .descriptifComplet .host p {
    font-size: 12px;
    line-height: 20px;
  }

  .tags {
    margin-top: 10px;
    gap: 10px;
  }

  .tags h4 {
    min-width: 84px;
    height: 18px;
    font-size: 10px;
    border-radius: 5px;
    padding-inline: 10px;
  }

  .descriptif_bandeau_right {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-top: 16px;
    margin-bottom: 16px;

  }

  .host {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    gap: 10px;
    margin-bottom: 0;
  }

  .stars {
    height: auto;
    gap: 7px;
  }

  .stars img {
    height: 14px;
  }

  .descriptif_corps {
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 20px;
  }

  .descriptif_corps div {
    margin-bottom: 0;
  }

  @media screen and (max-width: 600px) {

    .descriptif_corps {
      flex-direction: column;
      margin-top: 0px;
      margin-bottom: 0px;
    }


  }








}