
/* OK */
.Banner_nav {
    display: flex;
    height: 171px;
    margin-inline: auto;
    width: 95%;
    max-width: 1240px;
    justify-content: space-between;
    align-items: center;
  }

  .Banner_nav img {
    height: 62px;
  }
  
  /* ok */
  .navigation ul {
    display: flex;
    gap: 57px;
  }
  
  /* ok */
  .navigation ul a {
    text-decoration: none;
  }
  /* ok */
  .navigation ul a li {
    color: #ff6060;
    cursor: pointer;
  }
  
  /* ok */
  .navigation ul .nav-active {
   text-decoration: underline;
   color: #ff6060;
  }
  
  /* ok */
  li {
    list-style-type: none;
    font-size: 24px;
  }
  

  @media screen and (max-width: 600px) {
    .Banner_nav {
        height: 83px;
        width: 95%;
      }
      
      .Banner_nav img {
        height: 43px;
      }

      .navigation ul {
        gap: 10px;
      }
      li {
        font-size: 18px;
      }







}