.cards {
  margin-inline: auto;
  margin-top: 43px;
  max-width: 1240px;
  width: 95%;
  padding-inline: 50px;
  padding-top: 56px;
  background: #f5eeee;
  border-radius: 25px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  grid-gap: 10px;
}

.fiche .card {
  margin-inline: auto;
  max-width: 340px;
  height: 340px;
  margin-bottom: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cardlogement {
  display: flex;
  position: relative;
  justify-content: left;
  width: 100%;
  height: 100%;
  border-radius: 18px;
}

.cardlogement h3 {
  position: absolute;
  max-width: 250px;
  bottom: 20px;
  left: 20px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 142%;
}

@media screen and (max-width: 600px) {
  .cards {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: transparent;
    margin-top: 0;
    row-gap: 20px;
    padding-inline: 19px;
    padding-top: 19px;
  }

  .fiche .card {
    margin-inline: auto;
  
    height: 255px;
    margin-bottom: 20px;
  }

  .cardlogement {
    border-radius: 10px;
  }
}
