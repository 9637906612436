/* ok */
.contenu {
  display: flex;
  flex-direction: column;

  margin-top: 106px;
  color: #ff6060;
}

/* ok */
span {
  font-size: 288px;
  font-weight: 700;
  text-align: center;
}

/* ok */
.message_erreur {
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  margin-top: 66px;
}

/* ok */
.page_erreur a {
  margin-top: 182px;

  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #ff6060;
  margin-bottom: 159px;
}

@media screen and (max-width: 600px) {
  .contenu {
    margin-top: 179px;
  }

  span {
    font-size: 96px;
  }

  .message_erreur {
    font-size: 18px;
    margin-inline: 50px;
    margin-top: 11px;
  }

  .page_erreur a {
    margin-top: 133px;

    font-size: 14px;

    margin-bottom: 235px;
  }
}
